<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CCard>
          <CCardHeader>
            <strong>Coin </strong><small>Edit Data</small>
          </CCardHeader>
          <CCardBody>
            <CForm @submit.prevent="updateCoin">
            <CRow>
              <CCol sm="6">
                <CInput
                  v-model="coin_name"
                  label="Name"
                  placeholder="Input Name"
                />  
              </CCol>
              <CCol sm="6">
                <CInput
                  v-model="coin_code"
                  label="Code"
                  placeholder="Input Code"
                />
              </CCol>
              <CCol sm="6">
                <CInput
                  v-model="coin_desc"
                  label="Desc"
                  placeholder="Input Desc"
                />  
              </CCol> 
            </CRow>            
            
                <div class="form-actions">
                  <CButton type="submit" color="primary" style='margin-right:15px'>Simpan</CButton>
                  <CButton color="secondary" to="/coins">Batal</CButton>
                </div>
            </CForm>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <CModal
      title="Error Input"
      color="danger"
      id="modalError"
    >
      <b>Error</b>! Data Coin Gagal Input.
    </CModal>

    <CModal color="danger" title="Error" :show.sync="myError" size="sm">
      <b>Gagal</b>! Data Coin Gagal Diperbaharui.
    </CModal>
   
  </div>    
  
</template>

<script>
import axios from 'axios';
import * as Modals from '../notifications/Modals.vue'
// import { CurrencyInput } from 'vue-currency-input'

export default {
  // components: {CurrencyInput },
  name: 'Forms',
  computed : {
    years () {
      const year = new Date().getFullYear()
      return Array.from({length: year - 2019}, (value, index) => new Date().getFullYear() - index)
    }
  },
  data () {
    return {
      myError : false,
      coin_name : "",
      coin_code : "",
      coin_desc : "",
      selected: [], // Must be an array reference!
      coin: [],
      show: true,
      horizontal: { label:'col-3', input:'col-9' },
      options: ['Option 1', 'Option 2', 'Option 3'],
      jenis: ['Penyelenggaran Jalan Kabupaten/Kota'],
      subjenis: ['Pembangunan dan Peningkatan Jalan', 'Rekonstruksi / Pemeliharaan / Rehabilitasi Jalan', 'Pembangunan Jembatan / Box Culvert'],
      tahuns: ['2021','2020'],
      selectOptions: [
        'Option 1', 'Option 2', 'Option 3',
        { 
          value: 'some value', 
          label: 'Selected option'
        }
      ],
      selectedOption: 'some value',
      formCollapsed: true,
      checkboxNames: ['Checkboxes', 'Inline Checkboxes',
                      'Checkboxes - custom', 'Inline Checkboxes - custom'],
      radioNames: ['Radios', 'Inline Radios',
                    'Radios - custom', 'Inline Radios - custom']
    }
  },
  mounted(){
  this.showCoin();
  },
  methods: {
    updateCoin: function(event){
      const kegiatan = { 
                          coin_desc: this.coin_desc, 
                          coin_code: this.coin_code,
                          coin_name: this.coin_name,
                          id_coin: this.$route.params.id_coin,
                          };
      axios.post(process.env.VUE_APP_BASE_URL+"api/public/index.php/updateCoin/?key=$2y$10$AnZ411EfYcBHTEMMYbXni.7HuKm5nf4fCleVEKMPs9dsDRKlrLCPu", kegiatan)
        .then((response) => {
            if(response.data=='sukses'){
              this.$router.push('/coins');
            }
            else{
              this.myError = true;
            }            
        })
    },
    showCoin: function(event){
      const pekerjaan = { 
                          coin_desc: this.coin_desc, 
                          coin_code: this.coin_code,
                          coin_name: this.coin_name,
                          };

      axios.get(process.env.VUE_APP_BASE_URL+"api/public/index.php/editcoin/"+this.$route.params.id_coin+"?key=$2y$10$AnZ411EfYcBHTEMMYbXni.7HuKm5nf4fCleVEKMPs9dsDRKlrLCPu")
        .then((response) => {
              this.coin_name=response.data.coin_name;
              this.coin_code=response.data.coin_code;
              this.coin_desc=response.data.coin_desc;
        })
    }
  }
}
</script>
